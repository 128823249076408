.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




#root .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;

}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

#root .ql-editor {
  min-height: 26em;
  border: none;
  height: 150px;
  overflow: scroll;
  font-size: 16px;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-let {
  text-align: left;
}

.ql-size-small {
  font-size: 12px !important;
}

.ql-size-large {
  font-size: 20px !important;
}

.ql-size-huge {
  font-size: 28px !important;
}

.ql-snow h1 {
  font-size: 2em !important;
}

.ql-snow h2 {
  font-size: 1.6em !important;
}

.ql-snow h3 {
  font-size: 1.2em !important;
}



.containers {
  display: flex;
  touch-action: none;
  width: 50%;
  height: 50%;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  height: 1024px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: right;
  padding: 0.5em;
}

.grid-item-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 0.5em;
}