@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }

  /* container */
  .nike-container {
    @apply w-11/12 m-auto;
  }

  /* Scroll */
  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }

  .scroll-style::-webkit-scrollbar {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: rgb(66, 245, 141) rgb(66, 245, 141);
  }

  .scroll-style::-webkit-scrollbar {
    @apply w-2;
  }

  .scroll-style::-webkit-scrollbar-track {
    @apply bg-slate-700;
  }

  .scroll-style::-webkit-scrollbar-thumb {
    @apply rounded-full bg-gradient-to-tr from-blue-500 to-green-500 via-teal-500;
  }

  /* Theme-Colors */
  .bg-theme {
    background-image: linear-gradient(0.2deg, rgba(40, 173, 222, 1) 4.8%, rgb(27, 76, 224) 85.5%);
  }

  .bg-theme-cart {
    @apply bg-gradient-to-b from-slate-900 to-gray-900 shadow-sm shadow-gray-900;
  }

  /* Clip-path */
  .clip-path {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%);
  }

  /* Blur-Effect */
  .blur-effect-theme {
    @apply bg-white/50 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
  }

  /* Icon Stles */
  .icon-style {
    @apply h-6 w-6 sm:h-5 sm:w-5 text-slate-100 cursor-pointer transition-all duration-100 active:scale-110 leading-tight;
  }

  /* text-gradient */
  .text-gradient {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-sky-500;
  }

  /* border */
  .rounded-theme {
    border-radius: 5% 100% 10% 5%;
  }

  /* button */
  .button-theme {
    @apply px-7 py-1 rounded active:scale-90 transition-all duration-100 ease-in-out shadow-md sm:text-sm;
  }

  /* tranistions-theme */
  .transitions-theme {
    @apply transition-all duration-700 ease-in-out cursor-pointer;
  }


  /*product page*/
  .main-container {
    @apply px-5 py-1 text-white bg-blue-950 smm:bg-gray-700;
  }

  .button-ls {
    @apply font-bold text-red-600 border-2 border-red-700 py-2 px-3 cursor-pointer hover:bg-red-600 hover:text-white transition ease-out duration-200;
  }

  .user {
    @apply flex justify-end gap-5;
  }

  .links {
    @apply p-3 flex justify-center items-start gap-2;
  }

  .link {
    @apply underline hover:text-red-500 hover:underline-offset-2 flex justify-center items-start gap-3 transition ease-out duration-200;
  }

  .title {
    @apply text-center border-b-2;
  }

  .title .main-title {
    @apply text-white text-2xl font-medium;
  }

  .title .sub-title {
    @apply text-green-600 text-2xl font-medium;
  }

  .card .title .sub-title {
    @apply text-green-600 text-sm font-medium;
  }

  .card .title .main-title {
    @apply text-white text-sm font-medium;
  }



  .card {
    @apply h-full w-full overflow-hidden relative rounded-3xl shadow-2xl;
  }

  .load-more {
    @apply font-semibold text-sm bg-slate-700 text-white flex justify-center hover:bg-yellow-300 hover:text-red-500 hover:text-2xl transition ease-out duration-200;
  }

  .dropdown-container select::-ms-expand {
    display: none;
  }

  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0)
    }

    25% {
      transform: translateX(5px)
    }

    50% {
      transform: translateX(-5px)
    }

    75% {
      transform: translateX(5px)
    }

    100% {
      transform: translateX(0)
    }
  }


  






}